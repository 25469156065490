import * as React from "react";
import { useState, useEffect } from "react";
import AsyncSelect from 'react-select/async';

type FormLine = {
  quantity: string;
  unit: string;
  ingredient: string;
};

const API_BASE = 'https://api.eatymologie.com/api/v1';
const API_URL = `${API_BASE}/recipes`;

const loadUnits = async () => {
  const res = await fetch(`${API_BASE}/units`);
  const data = await res.json();
  const formattedData = data.map((unit: any) => ({
    value: unit.id,
    label: unit.name,
  }));
  return formattedData;
}

const loadIngredients = async () => {
  const res = await fetch(`${API_BASE}/ingredients`);
  const data = await res.json();
  console.log(data);
  const formattedData = data.map((ingredient: any) => ({
    value: ingredient.id,
    label: ingredient.name,
  }));
  return formattedData;
}

const CreateRecipe = () => {
  const [name, setName] = useState("");
  const [label, setLabel] = useState("");
  const [instructions, setInstructions] = useState("");
  const [lines, setLines] = useState<FormLine[]>([
    { quantity: '', unit: '', ingredient: '' },
  ]);
  let units: any[] = [];
  let ingredients: any[] = [];

  const handleAddLine = (e: any) => {
    e.preventDefault();
    const newLine = { quantity: '', unit: '', ingredient: '' };
    setLines([...lines, newLine]);
  };

  const handleInputChange = (index: number, field: keyof FormLine, value: string) => {
    const updatedLines = [...lines];
    updatedLines[index][field] = value;
    setLines(updatedLines);
  };

  const loadUnitOptions = async (inputValue: string) => {
    if (!units.length) {
      units = await loadUnits();
      return units;
    }
    return units.filter((unit) =>
      unit.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  }

  const loadIngredientOptions = async (inputValue: string) => {
    if (!ingredients.length) {
      ingredients = await loadIngredients();
      return ingredients;
    }
    return ingredients.filter((ingredient) =>
      ingredient.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      units = await loadUnits();
      ingredients = await loadIngredients();

      // Update the dropdown options with the fetched data
    };

    fetchData();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // Do something with the form data
    const recipeData = {
      name,
      instructions,
      label,
      ingredients: lines,
    };

    const res = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(recipeData),
    });

    if (res.ok) {
      alert('Recipe created successfully');
    } else {
      alert('Failed to create recipe');
    }
  }

  return (
    <>
      <p className="text-lg pt-6">Enter the ingredients for your recipe, please.</p>
      <form
        className="grid grid-cols-1 gap-8 py-12 w-4/5 md:w-3/5 lg:w-3/5 xl:w-2/5"
        onSubmit={handleSubmit}
      >
        <label>
          <span>Name</span>
          <input
            className="mt-1 w-full block text-black rounded-md border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label>
            <span>Instructions</span>
            <textarea
            className="mt-1 w-full block text-black rounded-md border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
            placeholder="Step 1: Do this. Step 2: Do that."
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
            />
        </label>
        <label>
          <span>Label</span>
          <input
            className="mt-1 w-full block text-black rounded-md border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
            type="text"
            name="label"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </label>
        {lines.map((line, index) => (
        <div className="flex" key={index}>
          <label>
          <span>Quantity</span>
          <input
            className="mt-1 w-full block text-black rounded-md border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
            type="number"
            name="quantity"
            value={line.quantity}
            onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
          />
        </label>
        <label>
          <span>Unit</span>
          <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={loadUnitOptions}
            onChange={(selectedOption) => handleInputChange(index, 'unit', selectedOption?.value || '')}
          />
        </label>
        <label>
          <span>Ingredient</span>
          <AsyncSelect 
            cacheOptions
            defaultOptions
            loadOptions={loadIngredientOptions}
            onChange={(selectedOption) => handleInputChange(index, 'ingredient', selectedOption?.value || '')}
          />
        </label>
        </div>
      ))}
        <button onClick={handleAddLine}>Add Line</button>
        <button type="submit">Submit</button>
      </form>
    </>
  );
};

export default CreateRecipe;