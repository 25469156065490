import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/recipes");
  };

  return (
    <>
      <div className="absolute">
        <div className="bg-white rounded-full p-4 ml-10 mt-5 shadow-lg shadow-black">
          <h1 className="font-oleo font-bold text-2xl">Eatymologie</h1>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-2/3 h-screen">
          <img src="./italy.jpeg"></img>
        </div>
        <div className="h-screen w-1/3 background-white shadow-lg shadow-black">
          <form
            className="flex flex-col p-5 h-screen"
            onSubmit={handleSubmit}
          >
            <div className="my-auto">
              <div className="flex justify-center">
                <h1 className="text-2xl font-poppins">Login</h1>
              </div>
              <label>
                <span className="pl-4">Email</span>
                <input
                  className="mb-3 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
                  type="text"
                  name="email"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </label>
              <label>
                <span className="pl-4">Password</span>
                <input
                  className="my-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
                  type="text"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
              <div className="flex justify-center">
                <button type="submit" className="py-2 px-5 mt-2 text-darkGreen font-bold border border-white hover:border-gray-200 rounded-full transition-all">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Login;