import * as React from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`flex z-50 py-3 bg-primary border-transparent border-b-darkBorder`}
      >
        <div className="flex w-full">
          <div className="flex w-full ml-8">
            {/* TODO: These urls should switch to the main page if user is logged in. */}
            <Link to="/">
              <img src="/logo.png" alt="Logo" className="h-12 w-14 pr-3 pt-1" />
            </Link>
            <Link
              className="text-2xl font-oleo font-bold leading-relaxed inline-block py-2 whitespace-nowrap text-white"
              to="/"
            >
              Eatymology
            </Link>
          </div>
          <div className="flex mr-8 space-x-3">
            <Link
              className={`text-sm font-roboto leading-relaxed inline-block px-5 py-2 my-auto whitespace-nowrap rounded-full border-2 border-darkBg hover:bg-darkBg hover:text-brightPurple transition-all bg-green text-darkBg hover:text-neutral`}
              to="/create-recipe"
            >
              Add Recipe
            </Link>
            <Link
              className={`text-sm font-roboto leading-relaxed inline-block px-5 py-2 my-auto whitespace-nowrap rounded-full border-2 border-darkBg hover:bg-darkBg hover:text-brightPurple transition-all bg-green text-darkBg hover:text-neutral`}
              to="/create-ingredient"
            >
              Add Ingredients
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;