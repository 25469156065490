import * as React from "react";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";

import Header from "./components/Header";
import Login from "./pages/login";
import Recipe from "./pages/recipe";
import Recipes from "./pages/recipes";
import CreateRecipe from "./pages/create-recipe";
import CreateIngredient from "./pages/create-ingredient";

function App() {
  const { pathname } = useLocation();
  const hideHeaderPaths = ["/"];

  return (
    <div className="h-screen flex flex-col">
      {/* Only show the header on non-login screens */}
      {hideHeaderPaths.includes(pathname) ? null : <Header />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/recipes" element={<Recipes />} />
        <Route path="/recipe/:id" element={<Recipe />} />
        <Route path="/create-recipe" element={<CreateRecipe />} />
        <Route path="/create-ingredient" element={<CreateIngredient />} />
      </Routes>
    </div>
  );
}

export default App;
