import React from "react";
import { useNavigate } from "react-router-dom";

const Recipe = ({ recipe }) => {
  const navigate = useNavigate();

  return (
    <div className="relative border-darkBg border-[1px] rounded-3xl pt-2 bg-green hover:shadow-lg transition-all shadow-black">
      <button 
        onClick={() => navigate(`/recipe/${recipe.id}`)}
        className="w-full">
        <div className="flex flex-row justify-between px-6">
          <div className="flex flex-col justify-center">
            <span className="text-black-500 font-semibold text-3xl text-left text-neutral">
              {recipe.name}
            </span>
            <span className="text-black-500 text-left">
              {recipe.instructions.trim().substring(0, 100)}...
            </span>
          </div>
        </div>
      </button>
    </div>
  );
};

export default Recipe;