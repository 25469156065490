import * as React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const getRecipe = async (id: string) => {
  let recipe: any;
  try {
    const response = await fetch(`https://api.eatymologie.com/api/v1/recipes/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    recipe = await response.json();
  } catch (error) {
    console.error(error);
    return;
  }

  return recipe;
}


const Recipe = () => {
  const { id } = useParams();
  const [recipe, setRecipe] = React.useState<any>({});

  useEffect(() => {
    getRecipe(id).then((recipe) => {
      console.log(recipe);
      setRecipe(recipe);
    });
  }, [id]);

  return (
    <div className="flex flex-col">
      <span className="text-black-500 capitalize font-semibold mr-2 text-3xl">
        {recipe.name}
      </span>
      <span className="text-black-500 capitalize mr-2">
        {recipe.instructions}
      </span>
      {/* Iterate over the list of ingredients and add the name, unit, and quantity */}
      <ul>
        {recipe.ingredients?.map((item: any) => (
          <li key={item.id}>
            {item.ingredient.name} - {item.quantity} {item.unit.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Recipe;