import React, { useEffect, useState } from "react";

import RecipeCard from "../../components/RecipeCard";
import Loader from "../../components/Loader";

const REQUEST_TIMEOUT = 4000;

const fetchRecipes = async (setRecipes: any) => {
  let recipes: any;
  try {
    const response = await fetch(`https://api.eatymologie.com/api/v1/recipes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    recipes = await response.json();
    console.log(recipes);
  } catch (error) {
    console.error(error);
    return;
  }

  setRecipes(recipes);
  // setRecipes(recipes);
};

const Recipes = () => {
  const [recipes, setRecipes] = useState<any>([]);
  const [hasTimedout, setHasTimedout] = useState(false);

  useEffect(() => {
    fetchRecipes(setRecipes);
    setTimeout(() => {
      if (recipes.length < 1) {
        setHasTimedout(true);
      }
    }, REQUEST_TIMEOUT);
  }, []);

  return (
    <div className="relative flex items-center hide-scrollbar">
      <div className="w-full flex flex-col justify-between px-10">
        {/* <h1 className="text-3xl mb-2 font-roboto font-thin">My Recipesitories</h1> */}
        <h1 className="text-3xl mb-2 font-roboto font-thin">My Recipes</h1>
          {recipes.length < 1 ? (
            hasTimedout ? (
              <div className="w-full flex">
                <p className="text-md text-white pb-2 mx-auto">
                  No Recipes to Display
                </p>
              </div>
            ) : (
              <Loader size="50" />
            )
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {recipes.map((recipe) => <RecipeCard key={recipe.id} recipe={recipe} />)}
            </div>
          )}
      </div>
    </div>
  );
};

export default Recipes;