import * as React from "react";
import { useState } from "react";

const CreateIngredient = () => {
  const [name, setName] = useState("");

  const API_URL = 'https://api.eatymologie.com/api/v1/ingredients';

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!name) {
      alert('Name is required');
      return;
    }

    // Do something with the form data
    const ingredientData = {
      name,
    };

    const res = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(ingredientData),
    });

    if (res.ok) {
      alert('Ingredient created successfully');
      setName('');
    } else {
      alert('Failed to create ingredient');
    }
  }

  return (
    <div>
      <h1>Create Ingredient</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <button type="submit">Create Ingredient</button>
      </form>
    </div>
  );
}

export default CreateIngredient;